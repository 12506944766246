import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import SortDescIcon from 'react-icons/lib/fa/sort-desc'
import SortAscIcon from 'react-icons/lib/fa/sort-asc'
import SearchIcon from 'react-icons/lib/md/search'

import Checkbox from 'presentational/Checkbox'
import { NotificationContext } from 'functional/NotificationProvider'
import SelectInput from 'presentational/SelectInput'
import Button from 'presentational/Button'
import ContextMenuButton from 'presentational/ContextMenuButton'
import TextInput from 'presentational/TextInput'
import Loader from 'presentational/Loader'
import ConfirmBulkOpModal from 'functional/ConfirmBulkOpModal'

import { capitalize } from 'util/string'
import { cleanId } from 'util/api'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 0 1em;
`

const BulkOperationsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  margin-top: ${props => (props.footer ? '5px' : '15px')};
  margin-bottom: ${props => (props.footer ? '5px' : '0')};
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props =>
    !props.hasBulkOps && !props.noPadding ? '64px' : '0px'};

  // overflow-x: scroll;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: white;
`

const Context = styled.td`
  overflow: visible !important;
`

const StyledTable = styled.table`
  background: white;

  min-width: 100%;
  border-collapse: collapse;

  text-align: left;
  border-bottom: 1px solid #e5e5e5;

  thead {
    border-bottom: 1px solid #e5e5e5;
  }

  tfoot {
    border-top: 1px solid #e5e5e5;
  }

  th {
    cursor: pointer;
    user-select: none;

    color: ${props => props.theme.colors.text.label};
  }

  th,
  tfoot tr {
    height: 4em;
  }

  tbody tr:hover {
    background: #f5f5f5;
  }

  tbody tr {
    height: 2em;
  }

  th,
  td {
    padding-right: 1em;
  }

  th,
  td {
    white-space: nowrap;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  td.context {
    background: red;
  }

  th:nth-child(2),
  th:nth-child(3) {
    min-width: 6em;
  }
`

const BulkButton = styled(Button)`
  width: auto;
  margin-left: 1em;
`

const LoadMoreButton = styled(Button)`
  width: auto;
  margin-top: 1em;

  align-self: center;
`

class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sort: props.defaultSort || {},
      selected: [],
      bulkOp: '',
      filter: props.defaultFilter,

      loading: false,
    }
  }

  filterThrottle = null

  componentWillMount() {
    this.onQueryChange()
  }

  componentDidUpdate(_, prevState) {
    const { state } = this

    if (JSON.stringify(prevState.sort) !== JSON.stringify(state.sort))
      this.onQueryChange()

    if (prevState.filter !== state.filter) {
      if (this.filterThrottle) clearTimeout(this.filterThrottle)

      this.filterThrottle = setTimeout(() => this.onQueryChange(), 500)
    }
  }

  onSort(change) {
    const { sort, loading } = this.state
    const { value } = change

    if (loading) return

    if (!sort[value] || sort[value] === 'desc')
      this.setState({ sort: { [value]: 'asc' } })
    if (sort[value] === 'asc') {
      this.setState({ sort: { [value]: 'desc' } })
    }
  }

  onFilter(value) {
    const { loading } = this.state

    if (loading) return

    this.props.onFilter(value)

    this.setState({ filter: value })
  }

  getDataWithQueries(pageChanged = false) {
    const { sort, filter } = this.state
    const { filter: filterKey } = this.props

    const queries = { ...sort }

    if (filter) queries[filterKey] = filter

    return this.props.getData(queries, pageChanged)
  }

  onQueryChange = async (pageChanged = false) => {
    this.setState({ loading: true })

    await this.getDataWithQueries(pageChanged)

    this.setState({ loading: false })
  }

  pickSortIcon(value) {
    const { sort } = this.state
    const curr = sort[value]

    if (!curr) return null
    if (curr === 'desc') return <SortDescIcon />
    if (curr === 'asc') return <SortAscIcon />
  }

  isAllSelected() {
    const { selected } = this.state
    const { data } = this.props

    if (!data) return false

    return selected.length === data.length
  }

  isSelected(id) {
    const { selected } = this.state

    return selected.includes(id)
  }

  onMainSelect = () => {
    const { selected } = this.state
    const { data, idKey } = this.props

    this.isAllSelected()
      ? this.setState({ selected: [] })
      : this.setState({ selected: data.map(item => item[idKey]) })
  }

  onSelect = id => {
    const { selected, loading } = this.state

    if (loading) return
    if (!this.isSelected(id)) this.setState({ selected: [...selected, id] })
    else {
      const i = selected.indexOf(id)
      const copy = [...selected]

      copy.splice(i, 1)

      this.setState({ selected: copy })
    }
  }

  makeSingleOp = (op, item) => {
    if (!op || !op.handler) return () => null

    return async id => {
      this.setState({ loading: true, selected: [] })

      await op.handler(id, item)
      await this.getDataWithQueries()

      this.setState({ loading: false })
    }
  }

  makeBulkOp = (op, context) => {
    if (!op || !op.handler) return () => null

    return async ids => {
      try {
        const { selected } = this.state

        this.setState({ loading: true, selected: [] })

        await op.handler(selected)

        context.methods.toggleTopNotification(
          'success',
          this.props.t('bulk_operation.success')
        )

        await this.getDataWithQueries()

        this.setState({ loading: false })
      } catch (e) {
        context.methods.toggleTopNotification(
          'error',
          this.props.t('bulk_operation.error')
        )

        this.setState({ loading: false })
      }
    }
  }

  renderContextMenu(id, item) {
    const { singleOps } = this.props

    if (!singleOps) return null

    let context = {}
    singleOps.forEach(({ label, handler }, i) => {
      const op = this.makeSingleOp({ handler }, item)

      context[i] = { name: label, method: () => op(id, item) }
    })

    return (
      <ContextMenuButton
        context={context}
        left={
          this.props.contextMenuLeftOriented ||
          this.props.contextMenuLeftOrientedSmall
        }
        small={this.props.contextMenuLeftOrientedSmall}
        contextMenuBinIcon={this.props.contextMenuBinIcon}
      />
    )
  }

  renderFilter() {
    const { filter } = this.props
    const { filter: filterState } = this.state

    if (!filter) return null

    return (
      <FilterContainer>
        <SearchIcon size={24} />
        <TextInput
          noInline
          input={{
            name: filter,
            value: filterState,
            onChange: e => this.onFilter(e.target.value),
          }}
        />
      </FilterContainer>
    )
  }

  renderHead() {
    const { headers, bulkOps, singleOps, showId, sortabledId } = this.props

    if (!headers.length) return null

    const renderedHeaders = [
      bulkOps && (
        <th key="checkbox">
          <Checkbox
            checked={this.isAllSelected()}
            onChange={this.onMainSelect}
          />
        </th>
      ),
      showId && (
        <th
          key="idKey"
          onClick={
            sortabledId ? () => this.onSort({ value: 'order[id]' }) : undefined
          }
        >
          #{sortabledId && this.pickSortIcon('order[id]')}
        </th>
      ),
      ...headers.map(header => {
        if (typeof header === 'string') {
          return <th key={header}>{capitalize(header)}</th>
        }

        if (typeof header === 'object') {
          const { value, label, sortable } = header

          return (
            <th
              key={value}
              onClick={sortable ? () => this.onSort({ value }) : undefined}
            >
              {label || capitalize(value)}{' '}
              {sortable && this.pickSortIcon(value)}
            </th>
          )
        }
      }),
      singleOps && <th key="context" />,
    ]

    return (
      <thead>
        <tr>{renderedHeaders}</tr>
      </thead>
    )
  }

  getCellValue(value) {
    if (this.props.maxCellLength === -1 || value === undefined) {
      return value
    } else {
      if (value.length <= this.props.maxCellLength || !isNaN(value)) {
        return value
      } else {
        return value.slice(0, this.props.maxCellLength - 3) + '...'
      }
    }
  }

  renderBody() {
    const { data, bulkOps, idKey, mapRow, showId } = this.props

    let renderedRows

    if (data) {
      renderedRows = data.map((item, i) => (
        <tr key={i}>
          {[
            bulkOps && (
              <td key="checkbox">
                <Checkbox
                  checked={this.isSelected(item[idKey])}
                  onChange={() => this.onSelect(item[idKey])}
                />
              </td>
            ),
            showId && <td key="idKey">{cleanId(item[idKey])}</td>,
            ...mapRow(item).map((cell, i) => (
              <td key={i}>{this.getCellValue(cell)}</td>
            )),
            <Context key="context">
              {this.renderContextMenu(item[idKey], item)}
            </Context>,
          ]}
        </tr>
      ))
    }

    return <tbody>{renderedRows}</tbody>
  }

  renderBulkOperationsBody(context) {
    const { bulkOps } = this.props
    const { selected, bulkOp } = this.state

    const selectedOp = bulkOps.find(op => op.label === bulkOp)

    return (
      <React.Fragment>
        <SelectInput
          noInline
          options={[
            { label: this.props.t('default.select_operation'), value: '' },
            ...bulkOps.map(({ label }) => ({ label, value: label })),
          ]}
          input={{
            name: 'bulkSelect',
            value: bulkOp,
          }}
          onChange={value => this.setState({ bulkOp: value })}
        />
        {selectedOp && selectedOp.confirmModal ? (
          <BulkButton
            disabled={!bulkOp || !selected.length}
            onClick={() =>
              context.methods.openModal(() => (
                <ConfirmBulkOpModal
                  bulkOperation={selectedOp.label}
                  onClose={context.methods.closeModal}
                  onBulk={this.makeBulkOp(selectedOp, context)}
                  selected={selected.length}
                />
              ))
            }
          >
            {this.props.t('default.go')}
          </BulkButton>
        ) : (
          <BulkButton
            disabled={!bulkOp || !selected.length}
            onClick={this.makeBulkOp(selectedOp, context)}
          >
            {this.props.t('default.go')}
          </BulkButton>
        )}
      </React.Fragment>
    )
  }

  renderBulkOperations(footer = false) {
    const { bulkOps } = this.props

    let bulkControls
    if (bulkOps) {
      bulkControls = (
        <BulkOperationsContainer
          key={footer ? 'bulk-operation-footer' : 'bulk-operation-header'}
          footer={footer}
        >
          <NotificationContext.Consumer>
            {context => this.renderBulkOperationsBody(context)}
          </NotificationContext.Consumer>
        </BulkOperationsContainer>
      )
    }

    return bulkControls
  }

  renderLoadMore() {
    const { canLoadMore } = this.props

    return (
      canLoadMore && (
        <LoadMoreButton onClick={() => this.onQueryChange(true)}>
          {this.props.t('default.load_more')}
        </LoadMoreButton>
      )
    )
  }

  renderOverlay() {
    const { loading: forceLoading } = this.props
    const { loading } = this.state

    if (forceLoading || loading)
      return (
        <Overlay>
          <Loader size="large" />
        </Overlay>
      )

    return null
  }

  render() {
    const { bulkOps, noPadding } = this.props

    return (
      <Container hasBulkOps={bulkOps} noPadding={noPadding}>
        <HeaderContainer>
          {this.renderBulkOperations()}
          {this.renderFilter()}
        </HeaderContainer>
        <StyledTable>
          {this.renderHead()}
          {this.renderBody()}
        </StyledTable>
        {this.renderBulkOperations(true)}
        {this.renderLoadMore()}
        {this.renderOverlay()}
      </Container>
    )
  }
}

Table.propTypes = {
  data: PropTypes.array,
  getData: PropTypes.func.isRequired,
  idKey: PropTypes.string.isRequired,
  mapRow: PropTypes.func.isRequired,
  headers: PropTypes.array.isRequired,
  bulkOps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      handler: PropTypes.func,
    })
  ),
  defaultFilter: PropTypes.string,
  singleOps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      handler: PropTypes.func,
    })
  ),
  filter: PropTypes.string,
  loading: PropTypes.bool,
  canLoadMore: PropTypes.bool,
  contextMenuBinIcon: PropTypes.bool,
  onFilter: PropTypes.func,
  showId: PropTypes.bool,
  noPadding: PropTypes.bool,
  maxCellLength: PropTypes.number,
}

Table.defaultProps = {
  defaultFilter: '',
  onFilter: () => {},
  showId: true,
  noPadding: false,
  maxCellLength: -1,
  contextMenuBinIcon: false,
}

export default withTranslation('common')(Table)
