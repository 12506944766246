import React, { useState } from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import Table from 'functional/Table'

import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'
import HorizontalMenu from 'presentational/HorizontalMenu'

import ContentAssetContainer from 'containers/ContentAssetContainer'

const StyledH3 = styled(H3)`
  margin-top: 0;
`

const CardContainer = styled.div`
  background-color: white;
  flex: 1;
  margin: 0 20px 20px 0;
  padding: 40px 30px 0 30px;
`

const TopContentAssetsWidget = ({ onChangeTab, activeTab }) => {
  const { t, i18n } = useTranslation('common')

  const changeTab = id => {
    const tabs = ['today', 'week', 'month', 'total']
    onChangeTab(tabs[id], id)
  }

  return (
    <CardContainer>
      <StyledH3>{t('dashboard.top_assets_title')}</StyledH3>
      <Paragraph>{t('dashboard.top_assets_description')}</Paragraph>
      <div>
        <HorizontalMenu
          kind="mediumsmall"
          onChange={id => changeTab(id)}
          activeTab={activeTab}
          underlineBackground
          tabs={[
            { name: t('dashboard.today') },
            { name: t('dashboard.week') },
            { name: t('dashboard.month') },
            { name: t('dashboard.total') },
          ]}
        />
      </div>
      <Subscribe to={[ContentAssetContainer]}>
        {({ state: { getAnalyticsData, getAnalyticsLoading } }) => {
          return (
            <Table
              getData={() => {}}
              data={getAnalyticsData}
              idKey="id"
              noPadding
              maxCellLength={20}
              loading={getAnalyticsLoading}
              mapRow={({ title, displays, converts }) => {
                const CVR = Math.round((100 / displays) * converts * 100) / 100
                return [
                  title,
                  displays,
                  converts,
                  `${
                    CVR && String(CVR).split('.')[1] === undefined
                      ? CVR + '.00'
                      : CVR && String(CVR).split('.')[1].length < 2
                      ? CVR + '0'
                      : CVR || '0.00'
                  }%`,
                ]
              }}
              headers={[
                {
                  label: t('dashboard.top_assets_table_title'),
                  sortable: false,
                  value: 'title',
                },
                {
                  label: t('dashboard.top_assets_table_displays'),
                  sortable: false,
                  value: 'displays',
                },
                {
                  label: t('dashboard.top_assets_table_converts'),
                  sortable: false,
                  value: 'countConverts',
                },
                {
                  label: t('dashboard.top_assets_table_CVR'),
                  sortable: false,
                  value: 'CVR',
                },
              ]}
            />
          )
        }}
      </Subscribe>
    </CardContainer>
  )
}

export default TopContentAssetsWidget
