import React, { Component } from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { withTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import Table from 'functional/Table'
import Search from 'functional/Search'
import Button from 'presentational/Button'
import Loader from 'presentational/Loader'
import Paragraph from 'presentational/Paragraph'
import FormError from 'presentational/FormError'
import GridTableSwitch from 'functional/GridTableSwitch'
import { NotificationContext } from 'functional/NotificationProvider'
import ContentAssetListItem from 'presentational/ContentAssetListItem'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'
import ConfirmContentAssetDeleteModal from 'functional/ConfirmContentAssetDeleteModal'

import {
  HeaderContainer,
  SubHeaderContainer,
  HeaderButtonContainer,
  LoaderContainer,
  FooterContainer,
  ContentContainer,
} from 'presentational/ScreenLayoutContainers'

import ContentAssetContainer from 'containers/ContentAssetContainer'

import { cleanId } from 'util/api'
import { formatDate } from 'util/date'

const ContentAssetListHeaderContainer = styled(HeaderContainer)`
  background: #f1efed;
`

const ContentAssetListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  background: #f1efed;

  padding: 0 32px 32px;
`

const CenteredLoaderContainer = styled(LoaderContainer)`
  margin-top: 150px;
`

const ContentAssetListFooterContainer = styled(FooterContainer)`
  background: #f1efed;
  justify-content: center;
  align-items: center;
`

const LoaderWithMargin = styled(Loader)`
  margin-right: 15px;
`

const LoadMoreButton = styled(Button)`
  width: auto;

  align-self: center;
`
const SwitchContainer = styled.div`
  float: right;
  margin-top: 32px;
  margin-right: 32px;
`

const StyledContentContainer = styled(ContentContainer)`
  margin-top: 100px;
`

const FlexContainer = styled.div`
  display: flex;
`

class ContentAssetScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: '',
      view: 'grid',
      loading: false,
      loadMore: false,
    }

    this.filterThrottle = null
  }

  componentDidMount() {
    this.onQueryChange()
  }

  componentDidUpdate(_, prevState) {
    const { state } = this

    if (prevState.filter !== state.filter) {
      if (this.filterThrottle) clearTimeout(this.filterThrottle)

      this.filterThrottle = setTimeout(() => this.onQueryChange(), 500)
    }
  }

  getDataWithQueries(pageChanged = false) {
    const { filter } = this.state
    const filterKey = 'title'
    const queries = []

    if (filter) queries[filterKey] = filter

    return this.props.getContentAssets(queries, pageChanged)
  }

  onQueryChange = async (pageChanged = false) => {
    this.setState({ loading: true })

    await this.getDataWithQueries(pageChanged)

    this.setState({ loading: false })
  }

  onFilter(value) {
    const { loading } = this.state

    if (loading) return

    this.setState({ filter: value, loadMore: false })
  }

  renderLoadMore(total, actual) {
    if (actual < total)
      return (
        <React.Fragment>
          <LoadMoreButton
            onClick={() => {
              this.onQueryChange(true)

              this.setState({ loadMore: true })
            }}
          >
            {this.props.t('default.load_more')}
          </LoadMoreButton>
          {this.state.loading && <LoaderWithMargin size="large" />}
        </React.Fragment>
      )
  }

  switchView(view) {
    if (view === this.state.view) return

    this.setState({ view })

    if (view === 'grid') {
      this.onQueryChange()
    }
  }

  renderListItems = items =>
    items.map((item, key) => (
      <NotificationContext.Consumer key={key}>
        {context => (
          <ContentAssetListItem
            key={key}
            item={item}
            context={[
              {
                name: this.props.t('default.edit'),
                method: id => this.props.history.push(`assets/edit/${id}`),
              },
              {
                name: this.props.t('default.delete'),
                method: () =>
                  context.methods.openModal(() => (
                    <ConfirmContentAssetDeleteModal
                      id={cleanId(item['@id'])}
                      deleteContentAsset={this.props.deleteContentAsset}
                      toggleTopNotification={
                        context.methods.toggleTopNotification
                      }
                      closeModal={context.methods.closeModal}
                      title={item.title}
                    />
                  )),
              },
            ]}
          />
        )}
      </NotificationContext.Consumer>
    ))

  renderGridView = () => (
    <Subscribe to={[ContentAssetContainer]}>
      {({ state: { data, loading, error, metaData } }) => {
        if (error) {
          return <FormError>{error}</FormError>
        }

        return (
          <React.Fragment>
            <ContentAssetListHeaderContainer>
              <Search
                columns={[]}
                onChange={value => this.onFilter(value)}
                defaultValue={this.state.filter}
              />
            </ContentAssetListHeaderContainer>
            {loading && (
              <CenteredLoaderContainer>
                <Loader size="large" />
              </CenteredLoaderContainer>
            )}
            {data !== null && (!loading || this.state.loadMore) && (
              <React.Fragment>
                <ContentAssetListContainer>
                  {this.renderListItems(data)}
                </ContentAssetListContainer>
                <ContentAssetListFooterContainer>
                  {this.renderLoadMore(
                    metaData['hydra:totalItems'],
                    data.length
                  )}
                </ContentAssetListFooterContainer>
              </React.Fragment>
            )}
          </React.Fragment>
        )
      }}
    </Subscribe>
  )

  renderListView = () => (
    <Subscribe to={[ContentAssetContainer]}>
      {({
        state: { data },
        getContentAssets,
        getContentAssetsCanLoadMore,
        deleteContentAssets,
      }) => (
        <StyledContentContainer>
          <NotificationContext.Consumer>
            {context => (
              <Table
                getData={getContentAssets}
                data={data}
                idKey="@id"
                mapRow={({
                  title,
                  updatedAt,
                  createdAt,
                  displaysCount,
                  convertedContactsCount,
                }) => [
                  title,
                  convertedContactsCount,
                  displaysCount,
                  !createdAt ? '' : formatDate(createdAt),
                  !updatedAt ? '' : formatDate(updatedAt),
                ]}
                defaultFilter={this.state.filter}
                headers={[
                  {
                    label: this.props.t('content_assets.table_header_title'),
                    value: 'order[title]',
                    sortable: true,
                  },
                  {
                    label: this.props.t('content_assets.converted_users'),
                    value: 'order[convertedContactsCount]',
                    sortable: true,
                  },
                  {
                    label: this.props.t('content_assets.displays'),
                    value: 'order[displaysCount]',
                    sortable: true,
                  },
                  {
                    label: this.props.t('default.created_at'),
                    value: 'order[createdAt]',
                    sortable: true,
                  },
                  {
                    label: this.props.t('default.updated_at'),
                    value: 'order[updatedAt]',
                    sortable: true,
                  },
                ]}
                filter="title"
                canLoadMore={getContentAssetsCanLoadMore()}
                singleOps={[
                  {
                    label: this.props.t('default.edit'),
                    handler: id =>
                      this.props.history.push(`/assets/edit/${cleanId(id)}`),
                  },
                  {
                    label: this.props.t('default.delete'),
                    handler: (id, item) =>
                      context.methods.openModal(() => (
                        <ConfirmContentAssetDeleteModal
                          id={cleanId(id)}
                          deleteContentAsset={this.props.deleteContentAsset}
                          toggleTopNotification={
                            context.methods.toggleTopNotification
                          }
                          closeModal={context.methods.closeModal}
                          title={item.title}
                        />
                      )),
                  },
                ]}
                bulkOps={[
                  {
                    label: this.props.t('default.delete_selected'),
                    handler: deleteContentAssets,
                    confirmModal: true,
                  },
                ]}
                onFilter={value => this.setState({ filter: value })}
              />
            )}
          </NotificationContext.Consumer>
        </StyledContentContainer>
      )}
    </Subscribe>
  )

  render() {
    const { t } = this.props
    return (
      <LoggedInScreenContainer>
        <HeaderContainer>
          <H1>{t('content_assets.title')}</H1>
          <HeaderButtonContainer>
            <Button bold onClick={() => this.props.history.push('/assets/new')}>
              + {t('content_assets.add_button')}
            </Button>
          </HeaderButtonContainer>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{t('content_assets.sub_header_description')}</Paragraph>
        </SubHeaderContainer>
        <SwitchContainer>
          <GridTableSwitch
            onSwitch={view => this.switchView(view)}
            active={this.state.view}
          />
        </SwitchContainer>
        {this.state.view === 'grid'
          ? this.renderGridView()
          : this.renderListView()}
      </LoggedInScreenContainer>
    )
  }
}

const ContentAssetScreenWrapper = props => (
  <Subscribe to={[ContentAssetContainer]}>
    {({ getContentAssets, deleteContentAsset }) => (
      <ContentAssetScreen
        {...props}
        getContentAssets={getContentAssets}
        deleteContentAsset={deleteContentAsset}
      />
    )}
  </Subscribe>
)

export default withTranslation('common')(ContentAssetScreenWrapper)
